export const bgHomeImages = [
  {
    title: "Let us transform your lawn while you sit back and relax.",
    button: "Find Out More",
    image: require("./assets/img/bh-family-lawn-care-services-north-vancouver.jpg"),
    alt: "Child playing with a dog on a well-maintained lawn while BUR-HAN landscaping professionals work in the background",
  },
  {
    title: "Join Our Team and embark on a journey of growth and success.",
    button: "Apply Now",
    image: require("./assets/img/bh-commercial-landscaping-vancouver.jpg"),
    alt: "BUR-HAN Garden & Lawn Care team performing commercial landscaping services in Vancouver.",
  },
  {
    title:
      "The European Chafer Beetle is in your area. Contact us today for advice on avoiding damage to your lawn.",
    button: "Get a quote",
    image: require("./assets/img/bh-backyard-garden-lawn-design-vancouver.jpg"),
    alt: "Beautiful backyard lawn and flower garden designed by BUR-HAN Garden & Lawn Care in Vancouver.",
  },
];

export const TestiMonialsDetails = ({ testiMonialDetail }) => {
  const { name, description } = testiMonialDetail;
  return (
    <div className="item">
      <div className="shadow-effect">
        <div className="stars">
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
        </div>
        <q className="testimonial-description">{description}</q>
      </div>
      <div className="testimonial-name-tag">
        <h5 className="testimonial-name"> {name}</h5>
      </div>
    </div>
  );
};

export const testiMonials = [
  {
    description:
      "BUR-HAN Garden and Lawn Care was recommended to me and I always recommend them to my friends.",
    name: "Marlene C, North Vancouver",
  },
  {
    description:
      "I started using BUR-HAN Garden and Lawn Care on a friend's referral and have not looked back since. Great service and great delivery!",
    name: "Gordon, North Vancouver",
  },
  {
    description:
      "I used another service for my lawn but I couldn't be more happy for switching to BUR-HAN! Highly recommended.",
    name: "D. Wong, Burnaby",
  },
  {
    description:
      "I was recommended by a friend to use BUR-HAN Garden and Lawn Care for my lawn services; and was pleasantly surprised by their professionalism and excellent rates. Would highly recommend to other friends.",
    name: "Kelly M, North Vancouver",
  },
];

export const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: true,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

export const selectCity = [
  { value: "Vancouver", label: "Vancouver" },
  { value: "North Vancouver", label: "North Vancouver" },
  { value: "West Vancouver", label: "West Vancouver" },
  { value: "Burnaby", label: "Burnaby" },
  { value: "Richmond", label: "Richmond" },
  { value: "New Westminister", label: "New Westminister" },
  { value: "Coquitlam", label: "Coquitlam" },
  { value: "Port Coquitlam", label: "Port Coquitlam" },
  { value: "Port Moody", label: "Port Moody" },
  { value: "Maple Ridge", label: "Maple Ridge" },
  { value: "Delta", label: "Delta" },
  { value: "Surrey", label: "Surrey" },
  { value: "White Rock", label: "White Rock" },
];

export const servicesOption = [
  {
    value: "Landscape Maintenance",
    label: "Landscape Maintenance",
  },
  {
    value: "Residential Lawn Care",
    label: "Residential Lawn Care",
  },
  {
    value: "Hardscape Installs/Maintenance",
    label: "Hardscape Installs/Maintenance",
  },
  {
    value: " Softscape Installs/Maintenance",
    label: " Softscape Installs/Maintenance",
  },
  {
    value: "Designs and Modelling",
    label: "Designs and Modelling",
  },
  {
    value: "Snow Management",
    label: "Snow Management",
  },
];

export const hearaboutusOption = [
  { value: "Internet Search", label: "Internet Search" },
  { value: "Google", label: "Google" },
  { value: "Homestars", label: "Homestars" },
  { value: "Yelp", label: "Yelp" },
  { value: "Linkedin", label: "Linkedin" },
  { value: "Instagram", label: "Instagram" },
  { value: "Facebook", label: "Facebook" },
  { value: "Facebook Promo", label: "Facebook Promo" },
  { value: "Website", label: "Website" },
  { value: "Neighbour", label: "Neighbour" },
  { value: "Referral", label: "Referral" },
  { value: "Business Card", label: "Business Card" },
  { value: "Truck Sign", label: "Truck Sign" },
  { value: "Other", label: "Other" },
];

export const driverslicenseOption = [
  { value: "Class 5", label: "Class 5" },
  { value: "Class 7", label: "Class 7" },
  { value: "None", label: "None" },
];

export const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "5px",
    },
    "::-webkit-scrollbar-track": {
      background: "yellow",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

export const photosDesign = [
  {
    src: require("./assets/img/projects/design/landscape-terrace-design.jpeg"),
    width: 4,
    height: 3,
    title:
      "Modern Landscaping with Tiered Gardens and Fire Pit",
    alt: "Modern landscaping design with tiered garden beds, outdoor seating, and fire pit in Vancouver.",
  },
  {
    src: require("./assets/img/projects/design/residential-landscape-plan.jpg"),
    width: 4,
    height: 3,
    title:
      "North Vancouver Landscaping Design Plan",
    alt: "Detailed garden plan with lawn, flower beds, and privacy hedges for North Vancouver landscaping project",
  },
  {
    src: require("./assets/img/projects/design/front-yard-landscape-plan.jpg"),
    width: 4,
    height: 3,
    title:
      "Front Yard Landscaping Services in Vancouver",
    alt: "Front yard landscaping plan with pathways, colorful flowers, and a variety of shrubs.",
  },
  {
    src: require("./assets/img/projects/design/flower-bed-garden-design.jpeg"),
    width: 4,
    height: 3,
    title:
      "Custom Landscaping Plan for Vancouver Gardens",
    alt: "Detailed landscaping plan featuring plant types and layout for a lush garden in Vancouver.",
  },
  {
    src: require("./assets/img/projects/design/boulevard-garden-design.jpg"),
    width: 4,
    height: 3,
    title:
      "Low-Maintenance Boulevard Garden Design",
    alt: "Boulevard garden design with mulch and rock beds for aesthetic appeal and low maintenance.",
  },
  {
    src: require("./assets/img/projects/design/modern-patio-design.jpeg"),
    width: 4,
    height: 3,
    title: "Modern Patio Landscaping",
    alt: "Modern patio design with wooden steps and natural stone pavers in a landscaped yard.",
  },
  {
    src: require("./assets/img/projects/design/flagstone-garden-walkway.jpg"),
    width: 4,
    height: 3,
    title:
      "Flagstone garden walkway with partial sun and shade planting zones",
    alt: "Flagstone Walkway with Sun and Shade Plant Zones.",
  },
  {
    src: require("./assets/img/projects/design/backyard-landscape-overview.jpg"),
    width: 4,
    height: 3,
    title:
      "Backyard Landscaping Design in Vancouver",
    alt: "Comprehensive backyard garden layout with diverse plants and rock beds for a modern look.",
  },
  {
    src: require("./assets/img/projects/design/privacy-garden-layout.jpeg"),
    width: 4,
    height: 3,
    title:
      "Privacy Landscaping Solutions in Vancouver",
    alt: "Privacy garden plan showcasing strategic plant placement for a serene backyard in Vancouver.",
  },
  {
    src: require("./assets/img/projects/design/garden-irrigation-design.jpg"),
    width: 4,
    height: 3,
    title: "Efficient Irrigation Landscaping Plan",
    alt: "Landscaping irrigation zones for efficient water usage and lush greenery.",
  },
  {
    src: require("./assets/img/projects/design/raised-garden-planting-plan.jpg"),
    width: 4,
    height: 3,
    title: "Raised Garden Bed Landscaping ",
    alt: "Raised garden planting plan featuring hydrangeas and sustainable plant selections.",
  },
  {
    src: require("./assets/img/projects/design/backyard-garden-design.jpeg"),
    width: 4,
    height: 3,
    title:
      "Expert Landscaping Design with Hydrangeas",
    alt: "Professional landscaping design with vibrant hydrangeas and privacy fencing in Vancouver.",
  },
];

export const photosPavingStone = [
  {
    src: require("./assets/img/projects/pavingStone/paving-stone-driveway-north-vancouver.jpg"),
    width: 4,
    height: 3,
    title: "Elegant Paving Stone Driveway in North Vancouver",
    alt: "A wet paving stone driveway with a clean and elegant layout in North Vancouver",
  },
  {
    src: require("./assets/img/projects/pavingStone/custom-circular-paving-stone-patio.jpg"),
    width: 4,
    height: 3,
    title: "Beautiful Circular Paving Stone Patio Design",
    alt: "A circular paving stone patio surrounded by lush greenery and vibrant flowers",
  },
  {
    src: require("./assets/img/projects/pavingStone/landscaping-paving-stones-rock-garden.jpg"),
    width: 4,
    height: 3,
    title: "Rock Garden and Paving Stone Landscape Design",
    alt: "A modern landscaping design featuring paving stones and a rock garden with vibrant plants",
  },
  {
    src: require("./assets/img/projects/pavingStone/sidewalk-paving-stones-urban-setting.jpg"),
    width: 4,
    height: 3,
    title: "Durable Paving Stone Sidewalk Design",
    alt: "A sidewalk made of clean paving stones in a busy urban area",
  },
  {
    src: require("./assets/img/projects/pavingStone/stepping-stone-pathway-landscape-rocks.jpg"),
    width: 4,
    height: 3,
    title: "Stepping Stone Pathway for Residential Landscaping",
    alt: "A charming stepping stone pathway surrounded by large landscape rocks and greenery",
  },
  {
    src: require("./assets/img/projects/pavingStone/paving-stone-driveway-elegant-design.jpg"),
    width: 4,
    height: 3,
    title: "Spacious and Stylish Paving Stone Driveway",
    alt: "A spacious paving stone driveway in a suburban area with a clean and uniform design",
  },
  {
    src: require("./assets/img/projects/pavingStone/modern-outdoor-paving-stone-patio.jpeg"),
    width: 4,
    height: 3,
    title: "Contemporary Paving Stone Patio Design",
    alt: "A modern outdoor patio area with square paving stones and stylish furniture",
  },
  {
    src: require("./assets/img/projects/pavingStone/front-yard-paving-stone-walkway.jpeg"),
    width: 4,
    height: 3,
    title: "Paving Stone Walkway for a Cozy Front Yard",
    alt: "A paved walkway leading to a cozy front yard with lush plants and garden beds",
  },
  {
    src: require("./assets/img/projects/pavingStone/covered-patio-paving-stones.jpeg"),
    width: 4,
    height: 3,
    title: "Covered patio with paving stones",
    alt: "A covered patio with sleek paving stones and clean design in a landscaped backyard",
  },
  {
    src: require("./assets/img/projects/pavingStone/backyard-patio-paving-stones-and-garden-beds.jpeg"),
    width: 4,
    height: 3,
    title: "Backyard Paving Stone Patio with Garden Beds",
    alt: "A backyard patio area with well-laid paving stones and adjacent garden beds",
  },
  {
    src: require("./assets/img/projects/pavingStone/high-quality-paving-stone-driveway-vancouver.jpg"),
    width: 4,
    height: 3,
    title:
      "Paving Stone Driveway Design in Vancouver - Elegant Landscaping Solutions",
    alt: "Elegant paving stone driveway design in Vancouver with scenic views",
  },
  {
    src: require("./assets/img/projects/pavingStone/modern-backyard-paving-vancouver.jpg"),
    width: 4,
    height: 3,
    title:
      "Modern Backyard Paving Stones for Stylish Patios - Vancouver Landscaping",
    alt: "Modern backyard paving stones for a stylish patio in Vancouver",
  },
];

export const photosRetainingWall = [
  {
    src: require("./assets/img/projects/retainingWall/retaining-wall-lighting.jpeg"),
    width: 4,
    height: 3,
    title: "Modern Retaining Wall with LED Lighting",
    alt: "Modern retaining wall with integrated LED lighting for evening ambiance",
  },
  {
    src: require("./assets/img/projects/retainingWall/retaining-wall-with-steps.jpg"),
    width: 4,
    height: 3,
    title: "Retaining Wall with Steps for Accessibility",
    alt: "Retaining wall featuring wide steps for access and style",
  },
  {
    src: require("./assets/img/projects/retainingWall/retaining-wall-garden-design.jpeg"),
    width: 4,
    height: 3,
    title: "Garden Retaining Wall Design",
    alt: "Retaining wall integrated with a lush garden landscape",
  },
  {
    src: require("./assets/img/projects/retainingWall/stone-retaining-wall-with-steps.jpeg"),
    width: 4,
    height: 3,
    title: "Stone Retaining Wall with Steps",
    alt: "Stone retaining wall with integrated steps, designed for functional and aesthetic landscaping.",
  },
  {
    src: require("./assets/img/projects/retainingWall/retaining-wall-landscaping-design.jpeg"),
    width: 4,
    height: 3,
    title: "Innovative Retaining Wall Landscaping Design",
    alt: "Landscaped retaining wall providing a tiered garden aesthetic",
  },
  {
    src: require("./assets/img/projects/retainingWall/tiered-retaining-wall-with-lawn.jpg"),
    width: 4,
    height: 3,
    title: "Tiered Retaining Wall for Multi-Level Gardens",
    alt: "Tiered retaining wall with lush green lawn and landscaping for a multi-level yard.",
  },
  {
    src: require("./assets/img/projects/retainingWall/corner-retaining-wall.jpeg"),
    width: 4,
    height: 3,
    title: "Corner retaining Wall for Garden Landscaping",
    alt: "Corner retaining wall for garden edge landscaping",
  },
  {
    src: require("./assets/img/projects/retainingWall/concrete-block-retaining-wall.jpg"),
    width: 4,
    height: 3,
    title: "Concrete Block Retaining Wall with Steps",
    alt: "Durable concrete block retaining wall with integrated steps",
  },
  {
    src: require("./assets/img/projects/retainingWall/illuminated-tiered-retaining-wall.jpeg"),
    width: 4,
    height: 3,
    title: "Illuminated Tiered Retaining Wall with Pergola Lighting",
    alt: "Illuminated tiered retaining wall with ambient lighting under a pergola at night",
  },
  {
    src: require("./assets/img/projects/retainingWall/retaining-wall-with-path.jpeg"),
    width: 4,
    height: 3,
    title: "Retaining Wall with Pathway Integration",
    alt: "Retaining wall with integrated stone path for functional landscaping",
  },
  {
    src: require("./assets/img/projects/retainingWall/wood-retaining-wall-garden-bed.jpeg"),
    width: 4,
    height: 3,
    title: "Wood Retaining Wall for Modern Gardens",
    alt: "Wooden retaining wall with garden bed, providing a natural and elegant landscaping solution.",
  },
  {
    src: require("./assets/img/projects/retainingWall/decorative-retaining-wall.jpg"),
    width: 4,
    height: 3,
    title: "Decorative Retaining Wall for Modern Landscapes",
    alt: "Decorative retaining wall with a sleek modern finish",
  },
];

export const photosTurfInstalls = [
  {
    src: require("./assets/img/projects/turfInstalls/front-yard-sod-installation.jpg"),
    width: 4,
    height: 3,
    title: "Front Yard Sod Installation",
    alt: "Freshly installed sod in a front yard, creating a lush green lawn.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/lawn-with-hedge-border.jpg"),
    width: 4,
    height: 3,
    title: "Lawn with Hedge Border | Professional Lawn Care Services",
    alt: "Well-maintained lawn with a neatly trimmed hedge border and a curved walkway.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/landscaped-lawn-garden-beds.jpg"),
    width: 4,
    height: 3,
    title: "Landscaped Lawn & Garden Beds | Enhance Your Outdoor Space",
    alt: "Landscaped lawn featuring vibrant garden beds along a clean sidewalk.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/backyard-sod-installation.jpg"),
    width: 4,
    height: 3,
    title: "Backyard Lawn Installation | Fresh Sod for a Healthy Lawn",
    alt: "Backyard with newly installed sod and lush greenery.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/backyard-lawn-sod-installation.jpg"),
    width: 4,
    height: 3,
    title: "Striped Lawn Mowing | Expert Lawn Maintenance Services",
    alt: "Front yard lawn with a freshly mowed striped pattern for a professional look.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/curved-lawn-design.jpg"),
    width: 4,
    height: 3,
    title: "Curved Lawn Design | Creative Landscaping Solutions",
    alt: "Beautiful curved lawn design with lush green grass and a neat sidewalk.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/fresh-sod-installation-residential-yard.jpg"),
    width: 4,
    height: 3,
    title: "Fresh Sod Installation | Professional Lawn Replacement Services",
    alt: "Fresh sod installation near a residential walkway for a lush green lawn.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/lawn-landscaping-with-shrubs.jpg"),
    width: 4,
    height: 3,
    title: "Lawn Landscaping Services | Enhance Your Yard with Expert Solutions",
    alt: "Beautifully landscaped lawn with neatly arranged shrubs and fresh sod.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/sod-lawn-tree-mulching.jpg"),
    width: 4,
    height: 3,
    title: "Tree Mulching & Sod Lawn | Complete Lawn Care Solutions",
    alt: "Freshly laid sod with tree mulching for a well-maintained lawn.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/side-yard-sod-installation.jpg"),
    width: 4,
    height: 3,
    title: "Side Yard Lawn Solutions | Professional Sod Installation Services",
    alt: "Side yard with newly installed sod and well-maintained landscaping.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/striped-lawn-mowing-pattern.jpg"),
    width: 4,
    height: 3,
    title: "Striped Lawn Installation",
    alt: "Freshly installed sod lawn with professional mowing stripes for a clean finish.",
  },
  {
    src: require("./assets/img/projects/turfInstalls/front-yard-sod-installation.jpg"),
    width: 4,
    height: 3,
    title: "Front Yard Sod Installation | Lush Green Lawn Solutions",
    alt: "Freshly installed sod in a front yard, creating a lush green lawn.",
  },
];
export const photosPlantings = [
  {
    src: require("./assets/img/projects/plantings/emerald-cedar-privacy-hedge.jpg"),
    width: 4,
    height: 3,
    title: "Emerald Cedar Privacy Hedge Installation",
    alt: "Emerald cedar trees planted in a neat row for privacy along a wooden fence.",
  },
  {
    src: require("./assets/img/projects/plantings/mulch-garden-bed-design.jpg"),
    width: 4,
    height: 3,
    title: "Mulched Garden Bed with Decorative Plants",
    alt: "Garden bed with fresh mulch and colorful plants bordered by decorative stones.",
  },
  {
    src: require("./assets/img/projects/plantings/retaining-wall-garden-bed.jpg"),
    width: 4,
    height: 3,
    title: "Retaining Wall Garden Bed Landscaping",
    alt: "A tiered garden bed with mulch and plants along a stone retaining wall.",
  },
  {
    src: require("./assets/img/projects/plantings/front-yard-garden-bed-installation.jpg"),
    width: 4,
    height: 3,
    title: "Front Yard Garden Bed Installation",
    alt: "Front yard landscaped with neatly arranged plants and fresh mulch.",
  },
  {
    src: require("./assets/img/projects/plantings/side-yard-garden-bed.jpg"),
    width: 4,
    height: 3,
    title: "Side Yard Garden Bed Installation",
    alt: "Side yard with a variety of plants in a well-maintained garden bed.",
  },
  {
    src: require("./assets/img/projects/plantings/backyard-garden-landscaping.jpg"),
    width: 4,
    height: 3,
    title: "Backyard Garden Landscaping",
    alt: "Beautifully landscaped backyard with diverse plant selection and mulch.",
  },
  {
    src: require("./assets/img/projects/plantings/garden-bed-with-flowers.jpg"),
    width: 4,
    height: 3,
    title: "Garden Bed with Colorful Flowers",
    alt: "Beautiful garden bed featuring vibrant pink flowers and ornamental grasses.",
  },
  {
    src: require("./assets/img/projects/plantings/shrub-landscaping-against-stone-wall.jpg"),
    width: 4,
    height: 3,
    title: "Shrub Landscaping Against Stone Wall",
    alt: "Well-maintained shrubs and greenery against a modern stone wall for a natural aesthetic.",
  },
  {
    src: require("./assets/img/projects/plantings/rock-garden-landscaping-design.jpg"),
    width: 4,
    height: 3,
    title: "Rock Garden Landscaping Design",
    alt: "Beautifully arranged rock garden with ornamental grasses and flowering shrubs.",
  },
  {
    src: require("./assets/img/projects/plantings/japanese-garden-landscaping.jpg"),
    width: 4,
    height: 3,
    title: "Japanese Garden Landscaping",
    alt: "Serene Japanese-style garden with rock arrangements, mulch, and a decorative stone pagoda.",
  },
  {
    src: require("./assets/img/projects/plantings/shaded-garden-bed.jpg"),
    width: 4,
    height: 3,
    title: "Shaded Garden Bed with White Flowers",
    alt: "A shaded garden bed featuring lush greenery and blooming white flowers.",
  },
  {
    src: require("./assets/img/projects/plantings/rock-border-garden-design.jpg"),
    width: 4,
    height: 3,
    title: "Rock Border Garden Design",
    alt: "Stylish rock border separating mulch and pebble areas for a clean landscape design.",
  },
];


