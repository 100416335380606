import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import lushGreenLawn from "../../../assets/img/lush-green-lawn-with-rock-garden.jpg";
import landscapedLawn from "../../../assets/img/landscaped-lawn-with-hedge.jpg";
import colorfulFlower from "../../../assets/img/colorful-flower-garden-with-large-blooms.jpg";
import urbanPlanter from "../../../assets/img/urban-planter-with-ornamental-grasses.jpg";

export default function SustainableLandscapingBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "Sustainable Landscaping in North and West Vancouver | Eco-Friendly Tips";
    document.title = newTitle;

    const newDescription =
      "Discover sustainable landscaping tips for North and West Vancouver. Learn about water-efficient gardening, native plants, composting, fire prevention, and layered garden designs to create eco-friendly landscapes.";

    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Sustainable landscaping North Vancouver,Sustainable landscaping West Vancouver, Eco-friendly gardening North Shore,Green landscaping North Vancouver,Environmentally friendly landscaping West Vancouver,Native plants North Vancouver,Composting garden waste,Top dressing lawns with compost,Water-efficient landscaping tips,Layered garden designs Vancouver,Fire prevention landscaping tips,Organic gardening North Vancouver,Mulch and soil sustainability",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost sustainableLand">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Sustainable Landscaping:
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                A Vision for North and West Vancouver
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Sustainable landscaping is more than just a gardening style — it's
              a commitment to preserving the environment and promoting
              ecological health. In the lush and dynamic regions of North and
              West Vancouver, adopting sustainable gardening practices not only
              protects local ecosystems but also enhances the beauty and
              functionality of your property.
              <br />
              <img
                src={lushGreenLawn}
                alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              <span>What is Sustainable Landscaping?</span>
              <br />
              Sustainable landscaping involves creating gardens and outdoor
              spaces that work harmoniously with nature. It emphasizes
              conserving resources, reducing waste, and fostering biodiversity
              while crafting outdoor areas that are both practical and stunning.
            </p>
            <p>
              <span>Characteristics of a Sustainable Landscape:</span>
              <br />
              1. Efficient Water Use:
              <br />
              While the North Shore, including West Vancouver and North
              Vancouver, is blessed with high levels of rainfall, the region's
              expanding population puts increasing pressure on water reserves.
              This means that even in areas with significant precipitation,
              efficient water use is essential to ensure long-term
              sustainability. Thoughtful resource planning and innovative
              technology can help reduce waste and conserve water:
              <br />
              - Install drip irrigation systems to deliver water directly to
              plant roots, minimizing evaporation and runoff.
              <br />
              - Use rainwater harvesting systems like barrels or cisterns to
              collect and store rainwater for use during drier periods.
              <br />
              - Create rain gardens to capture and absorb excess runoff,
              preventing erosion and replenishing groundwater naturally.
              <br />
              - Utilize moisture-retaining soil amendments and mulches to reduce
              the need for frequent watering.
              <br />
              - Green gardens and lawns as fire prevention: A well-maintained
              green garden and lawn act as natural fire breaks, reducing the
              risk of fire spreading to your home. Keeping your landscaping lush
              and hydrated creates a barrier that protects your property from
              fire hazards, particularly in dry seasons.
              <br />
              <img
                src={urbanPlanter}
                alt="Dry grass spot on lawn caused by disease or lack of nutrients, needing proper lawn care."
                title="Dry Grass Spot Solutions | Lawn Care for Healthy Growth"
                className="img-responsive"
              />
              <span>2. Avoiding Banned Pesticides and Herbicides:</span>
              <br />
              Sustainable landscapes avoid the use of banned or harmful
              pesticides and herbicides, opting for natural and environmentally
              friendly solutions instead. Some effective alternatives include:
              <br />
              - Vinegar: An organic and affordable solution for controlling
              weeds in pathways and driveways.
              <br />
              -Flame Weeding: A safe and effective method of eliminating weeds
              using heat to kill them at the cellular level.
              <br />
              - Cultivation: Regularly loosening the soil with tools to prevent
              weed growth and disrupt their root systems.
              <br />
              - Hand-pulling or using mechanical methods to remove weeds
              manually, ensuring minimal disruption to surrounding plants.
              <br />
              - Companion Planting: Growing certain plants together to naturally
              repel pests. Encouraging beneficial insects, such as ladybugs,
              lacewings, and predatory beetles, to control harmful pests. By
              eliminating chemical-based products, you protect soil health,
              local waterways, and create a safer environment for people, pets,
              and wildlife.
              <br />
            </p>
            <p>
              <span>3. Reducing Open Spaces with Planting:</span>
              <br />
              Large open areas can contribute to water runoff, soil erosion, and
              wasted space. Sustainable landscapes minimize open spaces by:
              <br />
              - Filling gaps with diverse plantings to reduce bare soil
              exposure.
              <br />
              - Utilizing groundcovers or low-maintenance grasses to cover areas
              where other plants may not thrive.
              <br />
              - Designing living mulches, such as dense plantings that act as
              natural weed barriers and moisture retainers.
              <br />
            </p>{" "}
            <p>
              <span>4. Choosing the Right Plants:</span>
              <br />
              Selecting the right plants is essential for a sustainable
              landscape. To ensure success:
              <br />
              - Use native species where possible, as they are adapted to the
              local climate, soil, and conditions of North and West Vancouver.
              <br />
              - Make sure native plants are placed in the correct
              conditions—such as sun, shade, or moisture levels—that match their
              natural habitats. For example, sword ferns thrive in shady, moist
              areas, while native grasses prefer sunnier, drier spots.
              <br />
              - Complement native plants with drought-tolerant and
              pollinator-friendly species to further enhance sustainability and
              biodiversity. <br />
              - Opt for a mix of evergreens, deciduous plants, and flowering
              perennials to provide year-round interest and ecological benefits.
              <br />
            </p>
            <p>
              <span>
                5. Composting Grass Clippings, Leaves, and Other Garden Waste:
              </span>
              <br />
              Composting is a cornerstone of sustainable landscaping, turning
              yard waste into nutrient-rich compost that can be used to improve
              soil health. Key practices include:
              <br />
              - Collect grass clippings, fallen leaves, and other plant material
              for composting.
              <br />
              - Use a composting drum or rotating composter for the best
              results. These systems allow you to turn the compost weekly,
              aerating the materials and speeding up the decomposition process.
              <br />
              - Add moisture as needed to ensure the compost is slightly damp,
              which aids in breaking down the organic matter.
              <br />
              - Avoid adding diseased plants, weeds that have gone to seed, or
              chemically treated materials to maintain a healthy compost.
              <br />
              - Composting reduces waste, enriches the soil with organic matter,
              and supports the natural nutrient cycle, making it an essential
              practice for sustainable landscaping.
              <br />
            </p>
            <p>
              <span>6. Top Dressing Lawns with Compost:</span>
              <br />
              Applying a thin layer of composted material over your lawn—known
              as top dressing—is a simple yet effective way to improve the
              health and sustainability of your landscape. Benefits include:
              <br />
              - Nutrient Enrichment: Compost is rich in nutrients like nitrogen,
              phosphorus, and potassium, which are essential for healthy grass
              growth.
              <br />
              - Moisture Retention: The organic matter in compost helps retain
              moisture, reducing the need for frequent watering.
              <br />
              - Improved Soil Structure: Top dressing with compost enhances soil
              aeration, encourages beneficial microbial activity, and supports
              root development.
              <br />
              - Thicker, Greener Lawns: Over time, compost top dressing can
              result in a denser, greener lawn with fewer bare patches and
              weeds.
              <br />
            </p>
            <p>
              <span>7.Healthy Soil and Mulch Products:</span>
              <br />
              Healthy soil is the backbone of any sustainable garden. Adding
              mulch provides several advantages:
              <br />
              - It retains moisture, reducing the need for frequent watering.
              <br />
              - Suppresses weeds naturally, reducing reliance on chemical
              treatments.
              <br />
              - Enriches the soil with organic matter as it decomposes.
              <br />
              - Choose sustainable mulch options, such as bark mulch, compost,
              or wood chips.
              <br />
            </p>
            <p>
              <img
                src={landscapedLawn}
                alt="Patchy grass with visible lawn damage and discoloration in a residential yard."
                title="Lawn Damage Repair | Solutions for Patchy and Discolored Grass"
                className="img-responsive"
              />
              <span>
                8.Layering Gardens to Maximize Space, Texture, and Colour:
              </span>
              <br />
              Layering gardens involves arranging plants of varying heights,
              textures, and colours to create depth, visual interest, and
              functional ecosystems that mimic natural forest environments. This
              approach offers several benefits:
              <br />
              - Maximizes biodiversity by creating habitats for pollinators,
              birds, and beneficial insects.
              <br />
              - Reduces open spaces, helping prevent soil erosion and suppress
              weeds.
              <br />
              - Enhances aesthetic appeal by blending textures and colours. For
              example:
              <br />
              <ul>
                <li>
                  Use fine-textured plants like ferns or ornamental grasses to
                  contrast with bold-leafed shrubs.
                </li>
                <li>
                  Incorporate evergreens for year-round structure and combine
                  them with flowering perennials for seasonal bursts of colour.
                </li>
                <li>
                  Include plants with varying foliage colours, such as deep
                  greens, silvers, and burgundies, to add visual depth.
                </li>
              </ul>
              - Provides seasonal interest by including plants that bloom or
              display vibrant foliage during different times of the year.
              <br />
              By combining height, texture, and colour in your garden layers,
              you can create a harmonious and sustainable landscape that is both
              functional and beautiful.
            </p>
            <p>
              <span>9.Directing Water On Your Property:</span>
              <br />
              Preventing water runoff is key to a sustainable landscape. By
              managing water on-site, you:
              <br />
              - Replenish soil and groundwater. <br />
              - Protect nearby waterways from being overwhelmed by stormwater.
              <br />
              - Prevent erosion on slopes or exposed areas. <br />- Use
              permeable pavers, swales, or dry creek beds to direct and absorb
              water effectively.
            </p>
            <img
              src={colorfulFlower}
              alt="Patchy grass with visible lawn damage and discoloration in a residential yard."
              title="Lawn Damage Repair | Solutions for Patchy and Discolored Grass"
              className="img-responsive"
            />
            <p>
              <span>
                The Future of Sustainable Gardening in North and West Vancouver:
              </span>
              <br />
              Efficient water use, layered gardens with thoughtful textures and
              colours, composting yard waste, top dressing lawns with compost,
              avoiding banned pesticides and herbicides, and reducing open
              spaces are critical components of creating a landscape that works
              in harmony with nature. By integrating these sustainable
              practices, residents of North and West Vancouver can ensure their
              outdoor spaces are not only beautiful but also ecologically
              responsible.
              <br />
              If you're ready to transform your property into a sustainable
              landscape,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                {" "}
                BUR-HAN Garden and Lawn Care
              </strong>{" "}
              is here to help. From layered garden designs to eco-friendly
              composting and lawn care practices, we provide solutions tailored
              to the needs of your property and the unique environment of North
              and West Vancouver. Reach out today and take the first step toward
              a greener future.
            </p>
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
