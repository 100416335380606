import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  bgHomeImages,
  testiMonials,
  servicesOption,
  TestiMonialsDetails,
  options,
  selectCity,
  hearaboutusOption,
  styles,
} from "../../script";
import "normalize.css/normalize.css";
//Owl Carousel Libraries and Module
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// Count up numbers
import CountUp from "react-countup";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

import one from "../../assets/img/one.svg";
import two from "../../assets/img/two.svg";
import three from "../../assets/img/three.svg";
import four from "../../assets/img/four.svg";
import northShoreReadersChoiceBadge from "../../assets/img/partners/north-shore-readers-choice-badge-2023.png";
import consumerChoiceAward2024Badge from "../../assets/img/partners/consumer-choice-award-badge-2024.png";
import greatPlaceToWorkBadge from "../../assets/img/partners/great-place-to-work-badge-2024-2025.png";
import beeSafeLogo from "../../assets/img/partners/bee-safe-pesticide-free-badge.png";
import contractorCheckBadge from "../../assets/img/partners/contractorcheck-accredited-badge.png";
import homeStarsBadge from "../../assets/img/partners/homestars-trusted-professional-badge.png";
import icpiCertifiedBadge from "../../assets/img/partners/icpi-certified-concrete-paver-badge.png";
import redSealCertification from "../../assets/img/partners/red-seal-certification-badge.png";
import beeSafeBlueCircleLogo from "../../assets/img/bee-safe-be-pesticide-free-logo.png";
import bh30logo from "../../assets/img/BH30logo.png";
import burhanTeam from "../../assets/img/burhan-team.png";

const animatedComponents = makeAnimated();

export default function Home() {
  
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Home | BUR-HAN Garden & Lawn Care ";
    document.title = newTitle;

    const newDescription =
    "BUR-HAN Garden & Lawn Care | Top Landscaping & Lawn Care Services in Vancouver"
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Vancouver Landscaping,Landscaping services Vancouver,Landscaping companies Vancouver,Landscape Vancouver, Vancouver landscaping company, Landscaping North Vancouver BC, Landscaping Vancouver BC, Landscaping West Vancouver BC, Residential landscaping Vancouver, Commercial landscaping Vancouver,Eco landscaping Vancouver,Garden maintenance Vancouver,Hardscape installation Vancouver, Best landscaping company in Vancouver,Professional landscaping services in Vancouver,Affordable landscaping companies in Vancouver,Top-rated landscaping services near me, Eco-friendly landscaping solutions in Vancouver,Residential landscaping experts in Vancouver, Commercial landscaping companies in Vancouver,Hardscaping and landscaping specialists in Vancouver,Garden design and maintenance services in Vancouver,Custom landscaping projects in Vancouver, Year-round landscaping services in Vancouver,Best lawn care and landscaping services in Vancouver, Expert landscaping contractors in Vancouver, Vancouver landscaping company with great reviews, Experienced landscapers in Vancouver"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);
  

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailInput = form.current["email"].value;
    if (!emailRegex.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message

          // // Send event to Google Analytics
          // window.gtag("event", "email_sent", {
          //   event_category: "Email",
          //   event_label: "Email sent successfully",
          // });
          // // Increment a custom metric to track the number of emails sent
          // window.gtag("event", "email_sent_count", {
          //   event_category: "Email",
          //   event_label: "Email sent successfully",
          // });
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/"); // Navigate to the homepage
    window.location.reload(); // Reload the page
  };

  

  return (
    <div id="home" className="wrapper">
      <h1>BUR-HAN Garden & Lawn Care</h1>
      <Header />

      {/* ----------------- CAROUSEL SLIDER ----------------- */}
      <div className="slider-wrapper">
        {bgHomeImages.map((item, index) => (
          <div
            key={index}
            className="slider-content bg-fade"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <img
              className="beeLogo"
              src={beeSafeBlueCircleLogo}
              alt="Bee Safe Be Pesticide Free Blue Circle Logo"
            />
            <img
              className="bh30Logo"
              src={bh30logo}
              alt="BUR-HAN Garden and Lawn Care celebrating 29th anniversary since 1995"
            />

            <div className="inner">
              <h2 data-aos="fade-up" data-aos-delay="400">
                {item.title}
              </h2>
              {/* <button>{item.button}</button> */}
            </div>
          </div>
        ))}
      </div>
      {/* -------------- END OF CAROUSEL SLIDER -------------*/}

      {/* -----------------  PARTNERS -----------------  */}
      <section id="partners" className="partners mb-5">
        <div className="container-partners" data-aos="zoom-in">
          <div className="row d-flex align-items-center justify-content-center">
            <h2 className="section-header">Awards and Affiliations</h2>
            <div className="col-1-5">
              <img
                src={northShoreReadersChoiceBadge}
                className="img-fluid partners-img partner1"
                alt="North Shore News Readers' Choice Winner 2023"
              />
            </div>
            <div className="col-1-5">
              <img
                src={consumerChoiceAward2024Badge}
                className="img-fluid partners-img partner2"
                alt="Consumer Choice Award Winner 2024 Vancouver"
              />
            </div>
            <div className="col-1-5">
              <img
                src={greatPlaceToWorkBadge}
                className="img-fluid partners-img partner3"
                alt="Great Place to Work Certified 2024-2025"
              />
            </div>
            <div className="col-1-5">
              <img
                src={beeSafeLogo}
                className="img-fluid partners-img partner4"
                alt="Bee Safe Initiative - Be Pesticide Free Logo"
              />
            </div>
            <div className="col-1-5">
              <img
                src={contractorCheckBadge}
                className="img-fluid partners-img partner5"
                alt="ContractorCheck Accredited Member Badge"
              />
            </div>
            <div className="col-1-5">
              <img
                src={homeStarsBadge}
                className="img-fluid partners-img partner6"
                alt="HomeStars Trusted Professional Logo"
              />
            </div>
            <div className="col-1-5">
              <img
                src={icpiCertifiedBadge}
                className="img-fluid partners-img partner7"
                alt="ICPI Certified Concrete Paver Installer"
              />
            </div>
            <div className="col-1-5">
              <img
                src={redSealCertification}
                className="img-fluid partners-img partner8"
                alt="Interprovincial Standard Red Seal Certification"
              />
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF PARTNERS -------------*/}

      {/* -------------- ABOUT -------------- */}
      <section id="about" className="about">
        <h2 className="section-header mb1 nodash">
          Make your landscaping project a true success
        </h2>
        <hr />
        <div className="row writtenContent">
          <div
            className="col-lg-6 order-1 order-lg-2 px-5 "
            data-aos="fade-left"
          >
            <img
              src={burhanTeam}
              alt="BUR-HAN Landscaping Team "
              className="img-fluid bhTeam"
            />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <div className="corner-left">
              <div className="title" data-aos="zoom-in">
                <h3>BUR-HAN GARDEN & LAWN CARE</h3>
              </div>
              <div
                className="aboutSentence"
                data-aos="fade-right"
                data-duration="2000"
              >
                <p>
                  BUR-HAN Garden & Lawn Care has been providing Residential &
                  Commercial landscaping services for over 30 years. <a href="/our-projects" className="internal-link">View our completed landscaping projects here.</a> It is our
                  goal to provide our clients with the best possible landscaping
                  services in Vancouver. We are passionate about creating
                  outdoor environments that inspire and captivate. With our
                  unwavering commitment to excellence, we go above and beyond to
                  exceed our clients expectations. From the initial consultation
                  to the final touches, our experienced team works closely with
                  you, ensuring that every detail aligns with your values and
                  preferences. With our comprehensive range of services,
                  including landscape design, installation, and maintenance, we
                  have the expertise to handle projects of any size and
                  complexity. Whether you envision a lush garden, show stopping
                  hardscape, or a sustainable landscape design, our dedicated
                  team of professionals are here to bring your dreams to life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF ABOUT -------------- */}

      {/* -------------- COUNTS -------------- */}
      <section id="counts" className="counts">
        <div className="container" data-aos="fade-up">
          <h2
            className="section-header whiteDash text-white"
            data-aos="zoom-in"
          >
            What we have achieved so far
          </h2>
        </div>
        <div className="row">
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-server"></i>
              <CountUp start={0} end={30} duration={3} enableScrollSpy={true} />
              <p>Years of Service</p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-emoji-smile"></i>
              <CountUp
                start={0}
                end={1030}
                duration={3}
                enableScrollSpy={true}
              />
              <p>Clients </p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-person"></i>
              <CountUp
                start={0}
                end={60}
                suffix="+"
                duration={3}
                enableScrollSpy={true}
              />
              <p>Team Members</p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-reception-4"></i>
              <CountUp
                start={0}
                end={400}
                duration={3}
                enableScrollSpy={true}
              />
              <p>Properties </p>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF COUNTS -------------- */}

      {/* --------------  SERVICES -------------- */}
      <section id="services" className="services section-bg">
        <h2 className="section-header" data-aos="fade-down">
          Our Services
        </h2>
        <p data-aos="zoom-in">
          "We offer excellent services, adapting to a wide range of preferences
          and requirements, ensuring your landscape achieves its utmost
          potential while simultaneously enhancing your property value."
        </p>
        <div className="row">
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/landscape-maintenance">
              <div className="servicesCard">
                <div className="cover item-a">
                  <h3>Landscape Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li>Lawn Maintenance</li>
                      <li>Garden Maintenance</li>
                      <li> Water bag installation and filling</li>
                      <li>Irrigation Management</li>
                      <li>Tree staking</li>
                      <li>Pest and Disease Control</li>
                      <li>Tree and Shrub care</li>
                      <li>Seasonal Clean-ups</li>
                      <li>Landscape Enhancement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-down"
          >
            <a href="/services/hardscape-installs-maintenance">
              <div className="servicesCard">
                <div className="cover item-b">
                  <h3>Hardscape Installs/Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li>Paver/slab patios and walkways</li>
                      <li>Modular block walls</li>
                      <li>New Irrigation Installations with New Landscapes</li>
                      <li>Grading and drainage solutions</li>
                      <li>Gravel/river rock installation</li>
                      <li>Excavation</li>
                      <li>Landscape Lighting</li>
                      <li>Water features</li>
                      <li>
                        Paver Maintenance Cleaning/Jointing/Levelling/Repair
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <a href="/services/design-and-modelling">
              <div className="servicesCard">
                <div className="cover item-c">
                  <h3>Designs and Modelling</h3>
                  <div className="card-back">
                    <ul>
                      <li>Consultation & Site Analysis </li>
                      <li>2D & 3D Design</li>
                      <li>Plant Selection</li>
                      <li>Hardscaping</li>
                      <li>Landscape Lighting</li>
                      <li>Irrigation</li>
                      <li>Construction Documentation & Project Management</li>
                      <li>Maintenance Plans</li>
                      <li>Permit Assistance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/residential-lawn-care">
              <div className="servicesCard">
                <div className="cover item-d">
                  <h3>Residential Lawn Care</h3>
                  <div className="card-back">
                    <ul>
                      <li>Weekly lawn mowing/edging</li>
                      <li>Fertilizing</li>
                      <li>Liming</li>
                      <li>Aeration</li>
                      <li>Moss Control</li>
                      <li>Organic Weed Control Program</li>
                      <li>Topdressing</li>
                      <li>Overseeding</li>
                      <li>European Chafer Beetle Control </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 md-3 col-sm-6 col-xs-12" data-aos="fade-up">
            <a href="/services/softscape-installs-maintenance">
              <div className="servicesCard">
                <div className="cover item-e">
                  <h3> Softscape Installs/Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li> 2D & 3D Design</li>
                      <li>Soil Analysis & Amendment </li>
                      <li> Material installation Mulch/Play-Chip/Rock/Stone</li>
                      <li>
                        Plant Selection & Installation
                        Annuals/Perennials/Trees/Shrubs/Hedges
                      </li>
                      <li>Edging Installations Garden Beds/Paths/Lawns</li>
                      <li> Garden Care and Maintenance</li>
                      <li>Pest and Disease Management</li>
                      <li>
                        Garden Reclamation Cleanups/Overgrown Blackberry+Bamboo
                        Control
                      </li>
                      <li>
                        Restoration Gardens/Native Plant Selection/Bi-law
                        Complaints
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <a href="/services/snow-management">
              <div className="servicesCard">
                <div className="cover item-f">
                  <h3>Snow Management</h3>
                  <div className="card-back">
                    <ul>
                      <li>Snow Plowing</li>
                      <li>Snow Shovelling & Snow Blowing</li>
                      <li>Salting, Pre & Post Treatments</li>
                      <li>24/7 Response</li>
                      <li>Seasonal Contracts</li>
                      <li>Snow & Weather Monitoring </li>
                      <li>Insured for Snow Services</li>
                      <li>Warehousing/ Commercial</li>
                      <li>Residential Homes & Strata</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      {/* -------------- END OF SERVICES -------------- */}

      {/* -------------- TESTIMONIALS --------------   */}
      <section id="testimonial" className="testimonials">
        <div className="container">
          <h2
            className="section-header"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            WHAT OUR CLIENTS ARE SAYING?{" "}
          </h2>
          <div className="text-center mb-5">
            <a
              href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x548670f2acf0caf5:0xa180b9dfb101ef53!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation"
              className="testimonial-button"
            >
              Google Reviews
            </a>
            <a
              href="https://homestars.com/companies/198943-bur-han-garden-lawn-care?utm_source=google&utm_medium=cpc&utm_campaign=b2c-unbranded-sn-west-vancouver-dsa-2023&keyword=&matchtype=&utm_term=&utm_content=650228789924_c_&gad_source=1&gclid=CjwKCAiAuNGuBhAkEiwAGId4ats_5fYuQPc5zcARrhPSCu5Tj_DOd2epYn-qfcpsKJmmdQAgu5mrdRoCgDYQAvD_BwE"
              className="testimonial-button"
            >
              HomeStars Reviews
            </a>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              id="customer-testimonoals"
              className="owl-carousel owl-theme"
              {...options}
            >
              {testiMonials.map((testiMonialDetail) => {
                return (
                  <TestiMonialsDetails
                    testiMonialDetail={testiMonialDetail}
                    key={testiMonialDetail._key}
                  />
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* -------------- END OF TESTIMONIALS -------------*/}

      {/* ----------------- VALUES ----------------- */}
      <section className="ourValues ">
        <h2 className="section-header my-5" data-aos="zoom-in">
          OUR VALUES
        </h2>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={one} style={{ width: "4.3rem" }} alt="number 1" />
              <h3>Community</h3>
              <p>- We care about nature</p>
              <p>- We care about each other</p>
              <p>- We care about our community</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="zoom-in"
          >
            <div className="content ">
              <img src={two} style={{ width: "4.3rem" }} alt="number 2" />
              <h3>Passion</h3>
              <p>- We have fun together</p>
              <p>- We recognize and celebrate our successes</p>
              <p>- We focus on the details and take pride in our work </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <div className="content">
              <img src={three} style={{ width: "4.3rem" }} alt="number 3" />
              <h3>Progressive</h3>
              <p>- We are collaborative and resourceful</p>
              <p>- We are focused on continuous improvement </p>
              <p>- We are committed to learning and development</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos-delay="300"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={four} style={{ width: "4.3rem" }} alt="number 4" />
              <h3>Integrity</h3>
              <p>- We are honest in our dealings </p>
              <p>- We are accountable and do what we say</p>
              <p>- We treat others and the environment with respect</p>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF VALUES -------------*/}

      {/* -------------- CONTACT --------------Í */}
      <section id="contact" className="contact">
        <div className="container">
          <h2
            className="section-header whiteDash"
            data-aos="zoom-in"
            data-aos-duration="1500"
            style={{ color: "var(--white-color)" }}
          >
            Contact
          </h2>
          <div className="row" data-aos="fade-up" data-aos-duration="1500">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <i className="fa-solid fa-location-dot"></i>
                <h3>Our Address</h3>
                <p>
                  Mailing Address : Suite #314 1641 Lonsdale Ave, North
                  Vancouver, BC, V7M 2J5
                </p>
                <p>
                  Shop Address : 2110 Front St, North Vancouver, BC, V7H 1A3
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa-solid fa-envelope"></i>
                <h3>Email Us</h3>
                <a
                  href="mailto:gardenandlawncare@bur-han.ca"
                  style={{ textDecoration: "none" }}
                >
                  <p>gardenandlawncare@bur-han.ca</p>
                </a>
                <br />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa-solid fa-phone"></i>
                <h3>Call Us</h3>
                <a href="tel:604-983-2687" style={{ textDecoration: "none" }}>
                  <p>(604) 983-2687</p>
                </a>
                <br />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-duration="2500">
            <div className="col-lg-6 ">
              <iframe
              title="google-maps-bur-han-garden-and-lawn-care"
                style={{ height: "100%", width: "100%", border: "0" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.4161203935946!2d-123.01846222309891!3d49.30640276907365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670f2acf0caf5%3A0xa180b9dfb101ef53!2sBUR-HAN%20Garden%20%26%20Lawn%20Care!5e0!3m2!1sen!2sca!4v1707501402244!5m2!1sen!2sca"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                allowFullScreen
              ></iframe>{" "}
            </div>
            <div className="col-lg-6">
              <form ref={form} onSubmit={sendEmail} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="First Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Last Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      placeholder="Address *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="postalcode"
                      id="postalcode"
                      placeholder="PostalCode *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <Select
                      id="select-city"
                      closeMenuOnSelect={true}
                      classNamePrefix="select-city"
                      className="select-city"
                      name="city"
                      placeholder="City *"
                      options={selectCity}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <Select
                      id="services"
                      isMulti
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="select"
                      className="select"
                      name="services"
                      placeholder="Services *"
                      options={servicesOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group mb-3">
                    <Select
                      id="hearaboutus"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="select"
                      className="select"
                      name="hearaboutus"
                      placeholder="How did you hear about us? *"
                      options={hearaboutusOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                </div>
                <textarea
                  id="requiredservices"
                  name="requiredservices"
                  className="col-md-12 form-group mb-3 form-control"
                  placeholder="Required Services *"
                  rows="4"
                  required
                />
                <div className="text-center">
                  <button type="submit" name="submit" id="submit-home">
                    Get A Quote
                  </button>
                </div>
              </form>

              {/* Popup form message */}
              {showSuccessMessage && (
                <div className="popup active">
                  <div className="popup-content">
                    <i
                      className="fa fa-check main-content__checkmark"
                      id="checkmark"
                    ></i>
                    <h2>Thank you for contacting us!</h2>
                    <h3>
                      We'll get back to you as soon as possible. <br />
                      Follow us on Social:
                    </h3>
                    <div className="social">
                      <a
                        href="https://www.facebook.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/readyforvideo"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-youtube"></i>
                      </a>
                      <a
                        href="https://www.pinterest.ca/bhgardenandlawn"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-pinterest"></i>
                      </a>
                    </div>
                    <button onClick={handleReload}>
                      <span>Back to the Homepage </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF CONTACT -------------*/}

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
