import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import LawnDamage from "../../../assets/img/lawn-damage-patchy-grass.jpg";
import LawnDisease from "../../../assets/img/lawn-disease-close-up-damaged-grass.jpg";
import DryGrass from "../../../assets/img/dry-grass-spot-treatment.jpg";

export default function RedThredBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "Red Thread Lawn Disease: Causes, Fixes & Prevention in Vancouver";
    document.title = newTitle;

    const newDescription =
      "Discover how to manage and prevent red thread lawn disease in Vancouver, North Vancouver, and West Vancouver. Learn its causes, fixes, and spring prevention tips.";

    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Red thread lawn disease, Red thread in Vancouver,	Red thread in North Vancouver,	Red thread in West Vancouver,	Lawn care for red thread,	Prevent red thread,	Secondary Keywords:,	Lawn fungus prevention,	Causes of red thread,	Spring lawn maintenance Vancouver,	Pacific Northwest lawn diseases,Lawn aeration and fertilization",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost redThread">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Red Thread:{" "}
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Causes, Fixes, and Spring Prevention Tips
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Red thread is a common lawn disease that can impact the appearance
              and health of your grass. If you've noticed unsightly patches of
              reddish-pink thread-like strands on your lawn, you're not alone.
              This disease thrives in the cool, damp climates of the Pacific
              Northwest, making it a prevalent concern in areas like Vancouver,
              North Vancouver, and West Vancouver. The good news is that red
              thread can be managed and even prevented with proper care.
              <br />
              <img
                src={LawnDisease}
                alt= "Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title= "Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              <span>What Causes Red Thread?</span>
              <br />
              Red thread is caused by a fungal pathogen (Laetisaria fuciformis)
              that thrives in specific conditions:
              <br />
              - Cool, wet weather: Common in spring and fall.
              <br />
              - Low nitrogen levels: Nutrient-deficient lawns are more
              susceptible.
              <br />
              - Compacted soil: Reduces grass health and root development.
              <br />
              - Poor air circulation: Shady areas or thick thatch can trap
              moisture, creating ideal conditions for fungal growth.
              <br />
            </p>

            <p>
              <span>How to Reverse Red Thread:</span>
              <br />
              1. Improve Lawn Nutrition:
              <br />
              - Apply a balanced nitrogen-rich fertilizer to strengthen your
              grass and help it recover.
              <br />
              - Avoid over-fertilizing, as this can cause other lawn issues.
              <br />
              2. Aerate Your Lawn:
              <br />
              - Aeration alleviates soil compaction, improves drainage, and
              promotes healthy root growth.
              <br />
              3. Remove Affected Grass:
              <br />
              - Mow and bag clippings from affected areas to reduce the spread
              of the fungus.
              <br />
              4. Enhance Air Circulation:
              <br />
              - Prune surrounding plants and trees to allow more sunlight and
              airflow.
              <br />
              <br />
              <img
                src={DryGrass}
                alt= "Dry grass spot on lawn caused by disease or lack of nutrients, needing proper lawn care."
                title= "Dry Grass Spot Solutions | Lawn Care for Healthy Growth"
                className="img-responsive"
              />
              <span> Preventing Red Thread in the Spring:</span>
              <br /> 1. Spring Fertilization:
              <br />
              - Apply a slow-release fertilizer in early spring to give your
              lawn the nutrients it needs to grow vigorously and resist
              diseases.
              <br />
              2. Aeration and Dethatching:
              <br />
              - Aerate the lawn in spring to improve water absorption and reduce
              compaction.
              <br />
              - Remove thatch if it exceeds ½ inch to discourage fungal growth.
              <br />
              3. Proper Watering Practices:
              <br />
              - Water deeply but infrequently, preferably in the morning, to
              prevent prolonged moisture on grass blades.
              <br />
              4. Overseeding with Resistant Grass Varieties:
              <br />
              - Use grass seed varieties resistant to red thread, such as
              perennial ryegrass or Kentucky bluegrass.
              <br />
            </p>
            <p>
              Red thread is a manageable lawn disease that can be addressed
              through proper care and maintenance. By understanding its causes
              and taking preventative measures, you can enjoy a lush, green lawn
              throughout the year.
              <br />
              If you/re dealing with red thread or want help preparing your lawn
              for spring,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                BUR-HAN Garden & Lawn Care
              </strong>{" "}
              offers expert lawn care services in Vancouver, North Vancouver,
              and West Vancouver. Contact us today to get started!
            </p>

            <img
              src={LawnDamage}
              alt= "Patchy grass with visible lawn damage and discoloration in a residential yard."
              title= "Lawn Damage Repair | Solutions for Patchy and Discolored Grass"
              className="img-responsive"
            />
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
