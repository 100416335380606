import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import raisedGardenBeds from "../../../assets/img/raised-garden-beds-with-irrigation.jpg";
import topviewRaised from "../../../assets/img/top-view-raised-garden-beds.jpg";
import woodenRaisedGarden from "../../../assets/img/wooden-raised-garden-bed-construction.jpg";
import gardenBedfoundation from "../../../assets/img/garden-bed-foundation-installation.jpg";
import raisedGardenBed from "../../../assets/img/raised-garden-bed-irrigation-installation.jpg";
import gravelBaseRaised from "../../../assets/img/gravel-base-raised-garden-bed.jpg";

export default function CedarTimberBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "Cedar Timber Raised Garden Beds: A Durable and Safe Choice Blog";
    document.title = newTitle;

    const newDescription = "Discover the benefits of cedar timber raised garden beds—durable, rot-resistant, and chemical-free. Learn how to build a long-lasting garden bed for vegetables, herbs, and flowers.";

    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = ["  Cedar raised garden beds, Best wood for raised garden beds, Cedar vs treated wood for garden beds, Safe wood for vegetable gardens, Rot-resistant wood for garden beds, How to build a cedar raised garden bed, DIY raised garden bed, Cedar garden bed benefits, Organic gardening with cedar beds,Cedar planters for vegetables"].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost cedarTimber">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Cedar Timber
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Raised Garden Beds: A Durable and Safe Choice
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Raised garden beds offer a practical and attractive way to grow
              vegetables, herbs, and flowers while improving soil quality,
              drainage, and accessibility. Choosing the right material is
              essential for ensuring durability and safety. Cedar timber stands
              out as the best option over treated woods due to its longevity,
              natural resistance to decay, and chemical-free composition.
              <br />
              <img
                src={gardenBedfoundation}
                alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              <span>Why Choose Cedar Over Treated Wood?</span>
              <br />
              <span>1. Natural Rot and Insect Resistance: </span>
              Cedar contains natural oils and tannins that make it highly
              resistant to decay and insect damage. Unlike treated wood, which
              relies on chemical preservatives, cedar withstands outdoor
              conditions naturally, making it a long-lasting and environmentally
              friendly choice.
              <br />
              <span>2. Chemical-Free and Safe for Growing Food: </span>
              Treated wood is infused with preservatives that can leach into the
              soil and be absorbed by plants. Cedar, on the other hand, is
              completely natural, making it the safest option for growing
              vegetables and herbs without concerns about chemicals.
              <br />
              <span>3. Aesthetic Appeal and Customization: </span>
              <br />
              <img
                src={raisedGardenBed}
                alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              <span>
                Cedar's warm, natural look enhances any garden space. You can
                choose between:
              </span>
              <br />
              • Rough-cut cedar for a rustic, natural appearance.
              <br />
              • Smooth cedar for a polished, refined finish.
              <br />
              Both options are equally durable and effective for raised garden
              beds.
              <br />
              <img
                src={gravelBaseRaised}
                alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              <span>Key Construction Considerations</span>
              <br />
              <span>Timber Dimensions and Bed Height:</span>
              <br />
              For structural integrity, 4x4, 6x6, and 4x6 cedar timbers are
              commonly used to build raised beds. These sizes provide a sturdy
              frame that supports the soil while ensuring long-term durability.
              <br />
              The ideal bed height depends on accessibility and comfort:
              <br />
              • Standard heights range from 12 to 24 inches.
              <br />
              • Taller beds (up to 30 inches) are great for accessibility,
              reducing strain on the back and knees.
              <br />
              <img
                src={woodenRaisedGarden}
                alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
                title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
                className="img-responsive"
              />
              A Solid Foundation for Longevity
              <br />
              To enhance stability and drainage, lay a 4 to 6-inch base of
              ¾-inch rock beneath the walls. This foundation:
              <br />- Prevents the wood from shifting or sinking over time. -
              Helps with drainage and reduces excess moisture. - Minimizes
              direct wood-to-soil contact, slowing decay. Soil Selection and
              Irrigation Planning
            </p>
            <img
              src={topviewRaised}
              alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
              title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
              className="img-responsive"
            />
            <p>
              <span>Choosing High-Quality Organic Soil: </span>
              <br />
              A well-balanced soil mix ensures healthy plant growth. A blend of
              topsoil, compost, and organic amendments provides proper drainage,
              root development, and nutrient availability.
              <br />
              Soil naturally settles over time, so periodic top-ups with organic
              matter help maintain soil quality.{" "}
            </p>
            <p>
              <span>Pre-Plumbing for Efficient Irrigation:</span>
              <br />
              Installing an irrigation system during construction simplifies
              watering and promotes plant health. Drip irrigation is one of the
              best options, delivering water directly to the roots while
              reducing evaporation and water waste.
            </p>
            <img
              src={raisedGardenBeds}
              alt="Close-up of damaged grass showing signs of lawn disease with brown and yellow patches."
              title="Lawn Disease Treatment | Fixing Brown and Yellow Grass Patches"
              className="img-responsive"
            />
            <p>
              <span>The Benefits of Raised Garden Beds:</span>
              <br />
              Raised beds offer several advantages over traditional in-ground
              planting:
              <br />
              - Reduced Weed Growth - A defined growing area limits weeds and
              simplifies maintenance.
              <br />
              - Improved Drainage - Elevated beds prevent waterlogging and
              promote better root aeration.
              <br />
              - Extended Growing Season - Soil in raised beds warms faster in
              the spring, allowing for earlier planting.
              <br />
              - Accessibility - Higher beds reduce strain on the back and knees,
              making gardening more comfortable for all ages.
              <br />
              If you're interested in cedar raised garden beds or need help
              designing and building one,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                {" "}
                BUR-HAN Garden and Lawn Care
              </strong>{" "}
              is here to assist. Contact us to discuss your project and get
              expert landscaping support.
            </p>
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
