import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photosPlantings } from "../../../script";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllProjects.css";

export default function Plantings() {
  
  const location = useLocation();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // Meta Tag changes
  useEffect(() => {
   const canonicalUrl = `https://bur-han.ca${location.pathname}`;
   const linkTag = document.querySelector('link[rel="canonical"]');
   if (linkTag) {
     linkTag.setAttribute("href", canonicalUrl);
   } else {
     const newLinkTag = document.createElement("link");
     newLinkTag.setAttribute("rel", "canonical");
     newLinkTag.setAttribute("href", canonicalUrl);
     document.head.appendChild(newLinkTag);
   }
 
   const newTitle = "Plantings Project | BUR-HAN Garden & Lawn Care";
   document.title = newTitle;

   const newDescription =
   "Discover our upcoming projects related to plantings and learn how our expert landscaping team can create stunning and functional plantings to enhance your outdoor space. Stay tuned for high-quality plantings services tailored to your specific needs and preferences.";
   const descriptionMetaTag = document.querySelector('meta[name="description"]');
   if (descriptionMetaTag) {
     descriptionMetaTag.setAttribute("content", newDescription);
   } else {
     const newMetaTag = document.createElement("meta");
     newMetaTag.setAttribute("name", "description");
     newMetaTag.setAttribute("content", newDescription);
     document.head.appendChild(newMetaTag);
   }
 
   const newKeywords = [
    "plantings, landscaping, outdoor projects, plantings design, plantings services, landscaping services, outdoor design, landscape design",
  ].join(", ");
   const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
   if (keywordsMetaTag) {
     keywordsMetaTag.setAttribute("content", newKeywords);
   } else {
     const newMetaTag = document.createElement("meta");
     newMetaTag.setAttribute("name", "keywords");
     newMetaTag.setAttribute("content", newKeywords);
     document.head.appendChild(newMetaTag);
   }
 
   const ogTitleTag = document.querySelector('meta[property="og:title"]');
   if (ogTitleTag) {
     ogTitleTag.setAttribute("content", newTitle);
   } else {
     const newOgTitleTag = document.createElement("meta");
     newOgTitleTag.setAttribute("property", "og:title");
     newOgTitleTag.setAttribute("content", newTitle);
     document.head.appendChild(newOgTitleTag);
   }
 
   const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
   if (ogDescriptionTag) {
     ogDescriptionTag.setAttribute("content", newDescription);
   } else {
     const newOgDescriptionTag = document.createElement("meta");
     newOgDescriptionTag.setAttribute("property", "og:description");
     newOgDescriptionTag.setAttribute("content", newDescription);
     document.head.appendChild(newOgDescriptionTag);
   }
 }, [location.pathname]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="wrapper">
      <Header />

      <div className="projectsGalleryHeader">
        <h1 data-aos="zoom-in"> Plantings</h1>
      </div>
      <div className="projectsGalleryMain" data-aos="fade-up">
       
        <h2 className="text-center">
          {" "}
          <a href="/services/hardscape-installs-maintenance">
            Click here to learn more about our services{" "}
          </a>{" "}
        </h2>
        <hr />
        <a href="/our-projects" className="to-top">
          Back to projects
        </a>

        <div className="gallery">
          <Gallery photos={photosPlantings} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photosPlantings.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                    alt: x.alt,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-8 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs. Contact us today to get a personalized landscaping plan tailored to your needs. Your dream garden is just one step away.
                </p>
              </div>
              <div className="col-md-4 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
