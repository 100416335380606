import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import LawnMaintenance from "../../../assets/img/lawn-maintenance-fertilizer-spreader.jpg";
import strataLand from "../../../assets/img/strata-landscaping-shrub-trimming.jpg";
import lawnMowingService from "../../../assets/img/lawn-mowing-service-residential-yard.jpeg";
import landscapeInstallation from "../../../assets/img/landscape-installation-modern-home.jpg";

export default function SpringLawnBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Spring Lawn and Garden Preparation Blog";
    document.title = newTitle;

    const newDescription = "Get your lawn and garden ready for spring! Discover essential services like aeration, lime application, fertilization, and cleanups in Vancouver, Burnaby, North Vancouver, West Vancouver, Coquitlam, and more. Schedule now for a healthy, vibrant yard!";

    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Spring garden preparation services in Vancouver,Lawn aeration and care services in West Vancouver,Lime application for acidic soil in North Vancouver ,Spring fertilization experts in Burnaby ,Garden cleanup solutions in New Westminster ,Comprehensive lawn care in Coquitlam ,Professional spring lawn services in Port Coquitlam ,Affordable lawn care solutions in Pitt Meadows ,Expert garden maintenance in Maple Ridge ,Lawn preparation services in the Lower Mainland"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost springLawn">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Spring Lawn
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                and Garden Tips for a Healthy Yard
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              As the days grow warmer and the snow melts away, spring is the
              perfect time to give your lawn and garden the care they need to
              thrive throughout the year. Preparing your outdoor spaces now
              ensures they look their best and stay healthy all season long.
              Here are the essential spring services your yard needs and how
              they can make a difference.
            </p>
            <img
              src={lawnMowingService}
              alt="Professional lawn mowing service in a residential backyard with a neatly trimmed lawn."
              title="Lawn Mowing Service | Residential Lawn Care Experts"
              className="img-responsive"
            />

            <p>
              <span><a href="/services/residential-lawn-care" style={{color:"var(--yellow-color)",textDecoration:"underscore"}}>Spring Aeration</a> - Revitalize Your Lawn:</span>
              <br />
              Over the winter months, soil can become compacted, restricting the
              flow of air, water, and nutrients to grass roots. Spring aeration
              helps to break up this compaction, creating channels that allow
              essential nutrients to reach the root zone. This process
              jumpstarts growth and ensures a lush, vibrant lawn as the season
              progresses.
              <br />
              <span><a href="/services/residential-lawn-care" style={{color:"var(--yellow-color)",textDecoration:"underscore"}}>Spring Lime Application</a> - Balancing Soil pH:</span>
              <br />
              Rainfall in our region often leads to acidic soil, which can make
              it harder for grass to absorb nutrients. A spring lime application
              restores balance to the soil's pH, ensuring that your lawn gets
              the nutrients it needs to thrive. By addressing this early in the
              season, you set the stage for healthy grass growth.
            </p>
            <img
              src={LawnMaintenance}
              alt="Well-maintained residential lawn by BUR-HAN with a garden and trees surrounding a cozyhouse"
              title="Beautiful Residential Lawn Maintenance by BUR-HAN"
              className="img-responsive"
            />
            <p>
              <span><a href="/services/residential-lawn-care" style={{color:"var(--yellow-color)",textDecoration:"underscore"}}>Spring Fertilization</a> - Feeding Your Lawn:</span>
              <br />
              After the long winter, your lawn needs a boost to recover and
              grow. Spring fertilization provides essential nutrients like
              nitrogen, phosphorus, and potassium that promote strong root
              development and green, healthy grass. A well-fertilized lawn not
              only looks great but is also more resilient to weeds and pests.
              <br />
              <img
              src={strataLand}
              alt= "landscapers in a property with hedges"
              title= "Professional Landscaping Maintenance"
              className="img-responsive"
            />
              <span> Garden Cleanups - A Fresh Start:</span>
              <br /> Spring cleanups are crucial for maintaining your garden's
              health and appearance. Removing debris, trimming back overgrown
              plants, and preparing flower beds for new growth ensures your
              plants have the space and nutrients they need to flourish. This
              step also helps prevent diseases and pests that can linger in
              leftover debris.
            </p>
            
            <p>
              <span>Why Now is the Time to Act:</span>
              <br />
              Spring is a short window of opportunity to set the tone for your
              lawn and garden's success. By tackling these tasks early, you'll
              save time and effort later in the season. Plus, a well-maintained
              yard enhances your property's curb appeal and provides a welcoming
              outdoor space for your family and friends to enjoy.
            </p>

            <p>
              <span><a href="/contact-us" style={{color:"var(--yellow-color)",textDecoration:"underscore"}}>Book </a>Your Spring Services Today:</span>
              <br />
              Don't wait until it's too late to give your lawn and garden the
              care they deserve. At <strong style={{color:"var(--yellow-color)"}}>BUR-HAN Garden & Lawn Care</strong>, we offer
              comprehensive spring services, including aeration, lime
              application, fertilization, and garden cleanups. We proudly serve
              Vancouver, West Vancouver, North Vancouver, Burnaby, New
              Westminster, Coquitlam, Port Coquitlam, Pitt Meadows, and Maple
              Ridge. Let our team of experts help you create a vibrant, healthy
              yard that's ready for the season ahead. Contact us today to
              schedule your spring services and get a head start on a beautiful
              outdoor space!
            </p>

            <img
              src={landscapeInstallation}
              alt="Beautiful landscape installation with artificial turf and stone pathway in a modern home."
              title="Landscape Installation Services | Professional Artificial Turf and Hardscaping"
              className="img-responsive"
            />
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
